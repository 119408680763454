/**
 * Full Image Gallery component.
 * @module components/media
 */
import { _extends } from '../lib/utils';

const media = {

	elem: {
		$body: $('body'),
		$slider: $('.media__items')
	},

	options: {
		adaptiveHeight: false,
		dots: false,
		draggable: true,
		fade: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		infinite: true,
		rows: 0,
		responsive: [],
 		prevArrow: '<button class="media__prev btn btn--square btn--medium"><span class="show-for-sr">Go to the previous slide</span><svg class="brei-icon brei-icon-chevron-right"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#brei-icon-chevron-right"></use></svg></button>',
		nextArrow: '<button class="media__next btn btn--square btn--medium"><span class="show-for-sr">Go to the next slide</span><svg class="brei-icon brei-icon-chevron-right"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#brei-icon-chevron-right"></use></svg></button>'
	},

	init() {

		if (this.elem.$slider.length > 0) {
			this.activateSliders();
		}

	},

	activateSliders() {

		const _this = this;

		this.elem.$slider.each(function () {

			let items = $(this).children('.media__imagery');
			let options = _extends({}, _this.options);

			if(items.length > 1) {
				items.each(function (index) {
					$(this).find('.media__caption p').prepend('<span class="media__count">' + (index + 1) + ' of ' + items.length + '</span>');
				});
			}

			$(this).slick(options);

		});

	}

};

export { media };

// to use:
//
// import media from './modules/media';
//
// media.init();
