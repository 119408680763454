import { ready } from './lib/utils';
import { plugin } from './lib/plugin';

import { cookie } from './modules/cookie';
import { elevator } from './modules/elevator';
import { announcements } from './modules/announcements';
import { masthead } from './modules/masthead';
import { media } from './modules/media';
import { modal } from './modules/modal';
import { navDesktop } from './modules/navDesktop';
import { navMobile } from './modules/navMobile';
import { Tooltip } from './modules/tooltip';
import { video } from './modules/video';

const main = {

	elem: {
		$textTooltip: $('.text-tooltip'),
		$tooltip: $('.tooltip')
	},

	init() {
		plugin('Tooltip', Tooltip);

		video.init();
		announcements.init();
		cookie.init();
		elevator.init();
		masthead.init();
		media.init();
		modal.init();
		navDesktop.init();
		navMobile.init();

		this.bindUIActions();
	},

	bindUIActions() {
		// Plugins

		this.elem.$tooltip.Tooltip({ type: 'button' });

		this.elem.$textTooltip.Tooltip({
			type: 'text',
			after(target) {
				$(target).toggleClass('text-tooltip--is-active');
			}
		});
	}


};

ready(function () {
	main.init();
});
