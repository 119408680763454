const masthead = {

	$window: $(window),

	elem: {
		$button: $('.masthead__btn')
	},

	init() {
		if (this.elem.$button.length > 0) {
			this.bindUIActions();
		}
	},

	bindUIActions() {
	
		this.elem.$button.on('click', function() {
			$(this).toggleClass('masthead__btn--is-playing');

			$(this).attr({
				'aria-label': function (i, txt) {
					return (txt.indexOf('Play') > -1) ? txt.replace('Play', 'Pause') : txt.replace('Pause', 'Play');
				},
				'aria-pressed': function (i, txt) {
					return (txt === 'false') ? 'true' : 'false';
				}
			});

			if ($(this).hasClass('masthead__btn--is-playing')) {
				$(this).siblings('.masthead__video').each(function() {
					this.play();
				});
			} else {
				$(this).siblings('.masthead__video').each(function() {
					this.pause();
				});
			}

		});

	}
};

export { masthead };
