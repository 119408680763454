const navMobile = {

	elem: {
		$children: $('.mobile-nav__children'),
		$menu: $('.mobile-nav__inner'),
		$toggle: $('.mobile-nav__toggle'),
		$trigger: $('.mobile-nav__trigger')
	},

	init() {
		this.elem.$toggle.on('click', function() {
			navMobile.elem.$toggle.attr({
				'aria-expanded': function (i, txt) {
					return (txt === 'false') ? 'true' : 'false';
				}
			});
			navMobile.elem.$toggle.children('.text').html(
				(navMobile.elem.$toggle.children('.text').html() === 'Menu') ? 'Close' : 'Menu'
			);
			navMobile.elem.$menu.attr({
				'aria-expanded': function (i, txt) {
					return (txt === 'false') ? 'true' : 'false';
				}
			}).slideToggle();
		});

		this.elem.$trigger.on('click', function() {
			let state = !($(this).attr('aria-expanded') === 'true');
			let controls = $('#' + $(this).attr('aria-controls'));

			navMobile.elem.$children.attr({ 'aria-expanded' : 'false' }).slideUp();
			navMobile.elem.$trigger.attr({ 'aria-expanded' : 'false' });

			$(this).attr({ 'aria-expanded' : state });
			if(state) {
				controls.attr({ 'aria-expanded' : 'true' }).slideDown();
			}

		});
	}

};

export { navMobile };
