const cookie = {

	elem: {
		$accept: $('#btn--accept'),
		$decline: $('#btn--decline'),
		$popup: $('.cookie')
	},

	init() {
		
		this.elem.$accept.on('click', function(e) {
			e.preventDefault();
			cookie.elem.$popup.addClass('cookie--selected');
		
			window.setTimeout(function() {
				cookie.elem.$popup.hide();
			},250);
		});

		this.elem.$decline.on('click', function(e) {
			e.preventDefault();
			cookie.elem.$popup.addClass('cookie--selected');

			window.setTimeout(function() {
				cookie.elem.$popup.hide();
			},250);
		});

	}

};

export { cookie };

// to use:
//
// import cookie from './modules/cookie';
//
// cookie.init();
