const announcements = {

	elem: {
		$button: $('.announcements__btn'),
		$list: $('.announcements__list')
	},

	init() {
		if (this.elem.$button.length > 0) {
			this.bindUIActions();
		}
	},

	bindUIActions() {
		this.elem.$button.on('click', function() {
			announcements.elem.$button.attr({
				'aria-label': function (i, txt) {
					return (txt.indexOf('Show') > -1) ? txt.replace('Show', 'Hide') : txt.replace('Hide', 'Show');
				},
				'aria-expanded': function (i, txt) {
					return (txt === 'false') ? 'true' : 'false';
				}
			});
			announcements.elem.$list.attr({
				'aria-expanded': function (i, txt) {
					return (txt === 'false') ? 'true' : 'false';
				}
			});
		});

		$(document).on('keydown', function(e) {
			let code = (e.keyCode) ? e.keyCode : e.which;
			if (code === 27) {
				announcements.elem.$button.attr({
					'aria-label': 'Show Announcements',
					'aria-expanded': 'false'
				});
				announcements.elem.$list.attr({
					'aria-expanded': 'false'
				});
			}
		});
	}

};

export { announcements };
