const navDesktop = {

	elem: {
		$desktopNav: $('.desktop-nav .menu-primary'),
		$desktopChild: $('.desktop-nav .menu-secondary, .desktop-nav .menu-tertiary')
	},

	bindUIActions() {
		this.elem.$desktopNav.on('keydown', this.onKeyDownPrimary.bind(this));
		this.elem.$desktopChild.on('keydown', this.onKeyDownSecondary.bind(this));
	},

	init() {
		this.bindUIActions();
	},

	onKeyDownPrimary(e) {
		const code = (e.keyCode ? e.keyCode : e.which);

		let item;
		let i;

		if (code === 37 || code === 39) {
			// Left arrow (37)
			if (code === 37) {
				item = $(document.activeElement).closest('.desktop-nav__item').prev();
			// Right arrow (39)
			} else if (code === 39) {
				item = $(document.activeElement).closest('.desktop-nav__item').next();
			}
			if (item.length > 0) {
				// Return to the first index or element within the list
				if (item.index() === ($('.desktop-nav__item').length)) {
					item = $('.desktop-nav__item').eq(0);
				}
				item.find('.menu-primary').focus();

			} else {
				i = (item.index() === -1 && code === 37) ? ($('.desktop-nav__item').length - 1) : 0;
				$('.desktop-nav__item').eq(i).find('.menu-primary').focus();
			}			
		}

		if (code === 40) {
			let hasChildList = $(document.activeElement).parent().find('.desktop-nav__menu').length > 0;

			if (hasChildList) {
				e.preventDefault(); // Prevent the page from scrolling up or down
				let $childList = $(document.activeElement).parent().find('.desktop-nav__menu');

				$childList
					.find('.desktop-nav__child').eq(0)
					.find('.menu-secondary, .menu-tertiary')
					.focus();
			}
		}
	},

	onKeyDownSecondary(e) {
		const code = (e.keyCode ? e.keyCode : e.which);

		let item;
		let i;

		if (code === 37 || code === 39) {
			e.preventDefault();

			// Left arrow (37)
			if (code === 37) {
				item = $(document.activeElement).closest('.desktop-nav__item').prev();
			// Right arrow (39)
			} else if (code === 39) {
				item = $(document.activeElement).closest('.desktop-nav__item').next();
			}

			if (item.length > 0) {
				// Return to the first index or element within the list
				if (item.index() === ($('.desktop-nav__item').length)) {
					item = $('.desktop-nav__item').eq(0);
				}
				item.find('.menu-primary').focus();

			} else {
				i = (item.index() === -1 && code === 37) ? ($('.desktop-nav__item').length - 1) : 0;
				$('.desktop-nav__item').eq(i).find('.menu-primary').focus();
			}			
		}

		if (code === 38 || code === 40) {
			e.preventDefault();
			let childItems = $(document.activeElement).closest('.desktop-nav__menu').find('.desktop-nav__child');

			if(code === 38) {
				item = childItems.index($(document.activeElement).closest('.desktop-nav__child')) - 1;
			} else if (code === 40) {
				item = childItems.index($(document.activeElement).closest('.desktop-nav__child')) + 1;
			}
			if ( item === -1 ) {
				item = childItems.length - 1;
			}
			if ( item === childItems.length ){
				item = 0;
			}
			childItems.eq(item).find('.menu-secondary, .menu-tertiary').focus();
		}

	}
};

export { navDesktop };
