const elevator = {

	elem: {
		$elevator: $('.elevator'),
	},

	init() {

		$(document).on('scroll resize', function() {
			if (document.documentElement.scrollTop > window.screen.height) {
				elevator.elem.$elevator.addClass('elevator--active');
			} else {
				elevator.elem.$elevator.removeClass('elevator--active');
			}
		});

	}

};

export { elevator };

// to use:
//
// import elevator from './modules/elevator';
//
// elevator.init();
